<template>
    <div class="aoi-form-wrapper behavior-wrapper">

        <div class="form-body-wrapper">
            <b-tabs lazy class="top-navbar">
                <b-tab title="Peak" active :title-item-class="{'validation-error': $v && $v.behaviors && ($v.behaviors.spike.delay.$error || $v.behaviors.spike.rateOfChange.$error)}">
                    <div class="flex row no-wrap">
                        <div class="tab-title-text">A Peak is an increase
                            followed by a decrease in the number of vehicles.
                        </div>
                    </div>
                    <div class="flex row no-wrap image-and-fields-wrapper">
                        <img width="300" height="200" src="/assets/images/graph-peak.svg"/>
                        <div class="aoi-group-label" >
                            <b-form-group class="pl-3 aoi-field-wrapper">
                                <div>
                                    <span class="aoi-label">Delay</span>
                                    <b-icon-info-circle class="aoi-label aoi-group-label-info" v-b-tooltip.hover
                                                        title="The number of seconds between the increase and decrease in vehicles"></b-icon-info-circle>
                                </div>
                                <b-form-select :class="{ 'validation-error': $v.behaviors.spike.delay.$error }"
                                               :state="validateState('spike', 'delay')"
                                               v-model="$v.behaviors.spike.delay.$model" :options="quantityOptions"
                                               class="aoi-dropdown"></b-form-select>
                                <b-form-invalid-feedback v-if="!$v.behaviors.spike.delay.required">Peak delay must
                                    not be empty
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="!$v.behaviors.spike.delay.numeric">Invalid Peak
                                    delay
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group class="pl-3 aoi-field-wrapper">
                                <div>
                                    <span class="aoi-label">Rate of Change</span>
                                    <b-icon-info-circle class="aoi-label aoi-group-label-info" v-b-tooltip.hover
                                                        title="Rate of change defines how steep the increase and decrease is in number of vehicles per second."></b-icon-info-circle>
                                </div>
                                <b-form-select
                                        :class="{ 'validation-error': $v.behaviors.spike.rateOfChange.$error }"
                                        :state="validateState('spike', 'rateOfChange')"
                                        v-model="$v.behaviors.spike.rateOfChange.$model" :options="quantityOptions"
                                        class="aoi-dropdown"></b-form-select>
                                <b-form-invalid-feedback v-if="!$v.behaviors.spike.rateOfChange.required">Peak rate
                                    of change must not be empty
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="!$v.behaviors.spike.rateOfChange.numeric">Invalid
                                    Peak rate of change
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                </b-tab>

                <b-tab title="Valley" :title-item-class="{'validation-error': $v && $v.behaviors && ($v.behaviors.treewell.delay.$error || $v.behaviors.treewell.rateOfChange.$error)}">
                    <div class="flex row no-wrap">
                        <div class="tab-title-text">A Valley is a decrease followed by a increase in the number of vehicles.</div>
                    </div>
                    <div class="flex row no-wrap image-and-fields-wrapper">
                        <img width="300" height="200" src="/assets/images/graph-valley.svg"/>
                        <div class="aoi-group-label">
                            <b-form-group class="pl-3 aoi-field-wrapper">
                                <div>
                                    <span class="aoi-label">Delay</span>
                                    <b-icon-info-circle class="aoi-label aoi-group-label-info" v-b-tooltip.hover
                                                        title="The number of seconds between the decrease and increase in vehicles"></b-icon-info-circle>
                                </div>
                                <b-form-select :class="{ 'validation-error': $v.behaviors.treewell.delay.$error }"
                                               :state="validateState('treewell', 'delay')"
                                               v-model="$v.behaviors.treewell.delay.$model"
                                               :options="quantityOptions" class="aoi-dropdown"></b-form-select>
                                <b-form-invalid-feedback v-if="!$v.behaviors.treewell.delay.required">Valley delay
                                    must not be empty
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="!$v.behaviors.treewell.delay.numeric">Invalid Valley
                                    delay
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group class="pl-3 aoi-field-wrapper">
                                <div>
                                    <span class="aoi-label">Rate of Change</span>
                                    <b-icon-info-circle class="aoi-label aoi-group-label-info" v-b-tooltip.hover
                                                        title="Rate of change defines how steep the decrease and increase is in number of vehicles per second."></b-icon-info-circle>
                                </div>
                                <b-form-select
                                        :class="{ 'validation-error': $v.behaviors.treewell.rateOfChange.$error }"
                                        :state="validateState('treewell', 'rateOfChange')"
                                        v-model="$v.behaviors.treewell.rateOfChange.$model"
                                        :options="quantityOptions" class="aoi-dropdown"></b-form-select>
                                <b-form-invalid-feedback v-if="!$v.behaviors.treewell.rateOfChange.required">Valley
                                    rate of change must not be empty
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="!$v.behaviors.treewell.rateOfChange.numeric">Invalid
                                    Valley rate of change
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                </b-tab>

                <b-tab title="Surge" :title-item-class="{'validation-error': $v && $v.behaviors && ($v.behaviors.surge.rateOfChange.$error)}">
                    <div class="flex row no-wrap">
                        <div class="tab-title-text">A Surge is an increase in the number of vehicles.</div>
                    </div>
                    <div class="flex row no-wrap image-and-fields-wrapper">
                        <img width="300" height="200" src="/assets/images/graph-surge.svg"/>
                        <div class="aoi-group-label">
                                <b-form-group class="pl-3 aoi-field-wrapper">
                                    <div>
                                        <span class="aoi-label">Increase Rate of Change</span>
                                        <b-icon-info-circle class="aoi-label aoi-group-label-info" v-b-tooltip.hover
                                                            title="This value defines how steep the increase is in number of vehicles per second."></b-icon-info-circle>
                                    </div>
                                    <b-form-select
                                            :class="{ 'validation-error': $v.behaviors.surge.rateOfChange.$error }"
                                            :state="validateState('surge', 'rateOfChange')"
                                            v-model="$v.behaviors.surge.rateOfChange.$model" :options="quantityOptions"
                                            class="aoi-dropdown"></b-form-select>
                                    <b-form-invalid-feedback v-if="!$v.behaviors.surge.rateOfChange.required">Surge rate
                                        of change must not be empty
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="!$v.behaviors.surge.rateOfChange.numeric">Invalid
                                        Surge rate of change
                                    </b-form-invalid-feedback>
                                </b-form-group>
                        </div>
                    </div>
                </b-tab>

                <b-tab title="Dive" :title-item-class="{'validation-error': $v && $v.behaviors && ($v.behaviors.dive.rateOfChange.$error)}">
                    <div class="flex row no-wrap">
                        <div class="tab-title-text">A Dive is a decrease in the number of vehicles.</div>
                    </div>
                    <div class="flex row no-wrap image-and-fields-wrapper">
                        <img width="300" height="200" src="/assets/images/graph-dive.svg"/>
                        <div class="aoi-group-label">
                            <b-form-group class="pl-3 aoi-field-wrapper">
                                <div>
                                    <span class="aoi-label">Decrease Rate of Change</span>
                                    <b-icon-info-circle class="aoi-label aoi-group-label-info" v-b-tooltip.hover
                                                        title="This value defines how steep the decrease is in number of vehicles per second."></b-icon-info-circle>
                                </div>
                                <b-form-select
                                        :class="{ 'validation-error': $v.behaviors.dive.rateOfChange.$error }"
                                        :state="validateState('dive', 'rateOfChange')"
                                        v-model="$v.behaviors.dive.rateOfChange.$model" :options="quantityOptions"
                                        class="aoi-dropdown"></b-form-select>
                                <b-form-invalid-feedback v-if="!$v.behaviors.dive.rateOfChange.required">Dive rate
                                    of change must not be empty
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="!$v.behaviors.dive.rateOfChange.numeric">Invalid Dive
                                    rate of change
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                </b-tab>

                <b-tab title="Oscillation" :title-item-class="{'validation-error': $v && $v.behaviors && ($v.behaviors.oscillation.$error)}">
                    <div class="flex row no-wrap">
                        <div class="tab-title-text">An Oscillation is an increase and decrease
                            followed by another increase and decrease in the number of vehicles.
                        </div>
                    </div>
                    <div class="flex row no-wrap image-and-fields-wrapper">
                        <img width="300" height="200" src="/assets/images/graph-oscillation.svg"/>
                        <div class="aoi-group-label">
                            <b-form-group class="aoi-field-wrapper">
                                <div>
                                    <span class="aoi-label">Oscillation Delay (sec)</span>
                                    <b-icon-info-circle class="aoi-label aoi-group-label-info" v-b-tooltip.hover
                                                        title="An Oscillation is a series of four events back-to-back. Increase, decrease, increase and decrease, in that order. This value is the number of seconds between the increase and decrease of each of the four events in the Oscillation"></b-icon-info-circle>
                                </div>
                                <b-form-select :class="{ 'validation-error': $v.behaviors.oscillation.$error }"
                                               :state="validateState('oscillation')"
                                               v-model="$v.behaviors.oscillation.$model" :options="quantityOptions"
                                               class="aoi-dropdown"></b-form-select>
                                <b-form-invalid-feedback v-if="!$v.behaviors.oscillation.required">Oscillation must
                                    not be empty
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback v-if="!$v.behaviors.oscillation.numeric">Invalid
                                    oscillation value
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                </b-tab>

                <b-tab title="Anomaly" :title-item-class="{'validation-error': $v && $v.behaviors && ($v.behaviors.abnormality.$error)}">
                    <div class="flex row no-wrap">
                        <div class="tab-title-text">An Anomaly is an unusual trajectory among detected pathways.</div>
                    </div>
                    <div class="flex row no-wrap image-and-fields-wrapper">
                        <img width="300" height="200" src="/assets/images/graph-anomaly.svg"/>
                        <div class="aoi-group-label">
                            <b-form-group class="aoi-field-wrapper">
                                <div>
                                    <span class="aoi-label">Anomaly</span>
                                    <b-icon-info-circle class="aoi-label aoi-group-label-info" v-b-tooltip.hover
                                                        title="An Anomaly is a behavior that is focused on the actions of a single vehicle and how it relates to others. A value of 5 will surface approx 5% least common points in dataset."></b-icon-info-circle>
                                </div>
                                <b-form-input :class="{ 'validation-error': $v.behaviors.abnormality.$error }"
                                              :state="validateState('abnormality')"
                                              v-model="$v.behaviors.abnormality.$model"
                                              class="aoi-field aoi-field-gray-bg"></b-form-input>
                                <b-form-invalid-feedback v-if="!$v.behaviors.abnormality.required">Anomaly must not
                                    be empty
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                        v-if="!$v.behaviors.abnormality.numeric || !$v.behaviors.abnormality.maxValue || !$v.behaviors.abnormality.minValue">
                                    Anomaly must be between 1 and 99
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                </b-tab>

                <b-tab title="Processing" :title-item-class="{'validation-error': $v && $v.behaviors && ($v.behaviors.smoothWindowLength.$error || $v.behaviors.batchSize.$error)}">
                    <div class="tab-title-text">Smoothing done on the object counts, like noise reduction.</div>
                    <div class="flex row no-wrap">

                    </div>

                    <div class="flex row no-wrap image-and-fields-wrapper">
                        <img width="300" height="200" src="/assets/images/graph-processing.svg"/>
                        <div class="aoi-group-label">
                            <b-form-group class="pl-3 aoi-field-wrapper">
                                <div>
                                    <label class="aoi-label">Smoothing (in seconds)</label>
                                    <b-icon-info-circle class="aoi-label" v-b-tooltip.hover
                                                        :title="'Weighted averaging of object counts for a specified time period to smooth object detection output'"></b-icon-info-circle>
                                </div>
                                <b-form-input
                                        :class="{ 'validation-error': $v.behaviors.smoothWindowLength.$error }"
                                        :state="validateState('smoothWindowLength')"
                                        v-model="$v.behaviors.smoothWindowLength.$model"
                                        class="aoi-field aoi-field-gray-bg"></b-form-input>
                                <b-form-invalid-feedback v-if="!$v.behaviors.smoothWindowLength.required">Human Time-To-Scale must not be empty</b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                        v-if="!$v.behaviors.smoothWindowLength.numeric || !$v.behaviors.smoothWindowLength.maxValue || !$v.behaviors.smoothWindowLength.minValue">
                                    Human Time-To-Scale must be between 1 and {{maxSmoothWindow}}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group class="pl-3 aoi-field-wrapper">
                                <div>
                                    <label class="aoi-label">Batch Size</label>
                                    <b-icon-info-circle class="aoi-label" v-b-tooltip.hover
                                                        :title="'Number of video frames processed at a time. Applicable only for GPU cluster.'"></b-icon-info-circle>
                                </div>
                                <b-form-input :class="{ 'validation-error': $v.behaviors.batchSize.$error }"
                                              :state="validateState('batchSize')"
                                              v-model="$v.behaviors.batchSize.$model"
                                              class="aoi-field aoi-field-gray-bg"
                                              :disabled="isEdit===true"></b-form-input>
                                <b-form-invalid-feedback v-if="!$v.behaviors.batchSize.required">Batch Size must not
                                    be empty
                                </b-form-invalid-feedback>
                                <b-form-invalid-feedback
                                        v-if="!$v.behaviors.batchSize.numeric || !$v.behaviors.batchSize.maxValue || !$v.behaviors.batchSize.minValue">
                                    Batch Size must be between 1 and 5
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
        <video ref="videopreviewRef" style="display: none" v-on:loadedmetadata="onLoadedVideoMetadata($event)" :src="previewSource" height="0" muted="true" disablePictureInPicture></video>

    </div>
</template>

<script>
    import {required, numeric, decimal, maxValue, minValue} from "vuelidate/lib/validators";
    import {validationMixin} from "vuelidate";

    export default {
        name: "BehaviorConfiguration",
        mixins: [validationMixin],
        beforeMount(){
            this.isEdit = this.$route.params.id !== 'new';
            this.sceneConfiguration = this.$parent.modelObject.scene;
            this.behaviors = this.$parent.modelObject.behaviors;
            if(this.isEdit){
                this.$emit('onNameChanged', this.sceneConfiguration.name);
                this.setPreviewSource(this.sceneConfiguration.containerName, this.sceneConfiguration.filePath)
            } else {
                const scene = this.$route.params.scene;
                if(scene) {
                    this.setPreviewSource(scene.$model.containerName, scene.$model.filePath)
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            to.params.behaviors = this.$v.behaviors;
            to.params.scene = this.$route.params.scene;
            if(to.name === 'completion') {
                if(this.$v.behaviors.$invalid){
                    this.isFormValid()
                } else {
                    next()
                }
            } else {
                next()
            }
        },
        data() {
            return {
                isEdit: false,
                sceneConfiguration: null,
                maxSmoothWindow: 99,
                behaviors: null,
                previewSource: '',
                objectOptions: [
                    {value: 'vehicle', text: 'Vehicle', selected: true}
                ],
                operatorOptions: [
                    {value: '=', text: '='},
                    {value: '>', text: '>'},
                    {value: '<', text: '<'}
                ],
                quantityOptions: [
                    {value: 1, text: '1'},
                    {value: 2, text: '2'},
                    {value: 3, text: '3'},
                    {value: 4, text: '4'},
                    {value: 5, text: '5'},
                    {value: 6, text: '6'},
                    {value: 7, text: '7'},
                    {value: 8, text: '8'},
                    {value: 9, text: '9'},
                    {value: 10, text: '10'}
                ],
            }
        },
        validations() {
            return {
                behaviors: {
                spike: {
                    delay: {required, numeric},
                    rateOfChange: {required, numeric},
                },
                surge: {
                    rateOfChange: {required, numeric},
                },
                dive: {
                    rateOfChange: {required, numeric},
                },
                treewell: {
                    delay: {required, numeric},
                    rateOfChange: {required, numeric},
                },
                oscillation: {
                    required, numeric
                },
                abnormality: {
                    required, numeric,
                    minValue: minValue(1),
                    maxValue: maxValue(99)
                },
                smoothWindowLength: {
                    required,
                    numeric,
                    minValue: minValue(1),
                    maxValue: maxValue(this.maxSmoothWindow)
                },
                batchSize: {
                    required,
                    numeric,
                    minValue: minValue(1),
                    maxValue: maxValue(5)
                }
            }
            }
        },
        methods: {
            validateState(name, innerField) {
                let field = this.$v.behaviors[name];
                if (innerField) {
                    field = field[innerField];
                }

                const {$dirty, $error} = field;
                return $dirty ? !$error : null;
            },
            isFormValid() {
                return !this.$v.behaviors.$invalid
            },
            setPreviewSource(container, filePath) {
                if(!this.$store.state.azureBlobData.data.sas.key || container !== this.$store.state.azureBlobData.data.sas.container) {
                    this.$store.dispatch('getSharedAccessSignature', container).then(() => {
                        this.previewSource = 'https://' + this.$store.state.settings.storageDetails.storageAccount + '.blob.core.windows.net/' + container + '/' + filePath + '?' + this.$store.state.azureBlobData.data.sas.key;
                    })
                } else {
                    this.previewSource = 'https://' + this.$store.state.settings.storageDetails.storageAccount + '.blob.core.windows.net/' + container + '/' + filePath + '?' + this.$store.state.azureBlobData.data.sas.key;
                }
            },
            onLoadedVideoMetadata(event) {
                this.maxSmoothWindow = Math.floor(event.target.duration / 2);
            },
        }
    }
</script>

<style lang="scss">
    .top-navbar{
        .nav {
            .validation-error {
                > a {
                    color: #FF526F!important;
                }
                > a:hover {
                    color: #FF526F!important;
                    border-color: #ddd!important;
                    border-top-color: #FF526F!important;;

                }
            }
        }
        .nav-link {
            color: #777;
            background-color: #212C38;
            padding-top: 8px;
            font-size: 12px;
        }
        .nav-item {
            .active:hover {
                border: 1px solid!important;
                border-bottom: none!important;
                border-top: 3px solid #0A78CC!important;
            }
        }
        .nav-tabs .nav-link:hover {
            color: #0A78CC;
            border: 0px;
        }
        .nav-tabs .navlink.active:hover {
            border: 1px solid white!important;
        }
        .nav-tabs .nav-link:focus {
            outline: none;
        }
        .nav-tabs .nav-link.active {
            background-color: #212C38 !important;
            color: #ddd;
            border-radius: 0;
            border-bottom: 0;
            padding-bottom: 12px;
            border-top: solid 3px #0A78CC;
        }
    }

    .behavior-wrapper {


        .tab-title-text{
            margin: 25px 0;
            color: #CED6E0;
        }
        .image-and-fields-wrapper {
            margin-top: 25px;
            > img {
                margin-bottom: 0!important;
                min-width: 300px;
                border: 1px solid #4B5561;
            }
        }

        > div {
            .aoi-group-label {
                min-width: 100px;
                flex-direction: row;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 25px;
            }

            .aoi-group-label-info {
                margin-top: -5px;
                margin-left: 5px;
            }

            .aoi-dropdown {
                font-size: 12px;
                font-weight: 400;
                color: #fff !important;
                border-radius: 4px;
                margin-top: 8px;
                background-color: rgb(33, 44, 56) !important;
                border: 1px solid #4B5561;

                .validation-error {
                    border-color: #FF526F !important;
                    border-width: 2px;

                    .validation-span {
                        font-size: 10px;
                        color: #FF526F;
                    }
                }
            }

            .aoi-dropdown:focus {
                box-shadow: 0 0 0 0.2rem rgba(13, 150, 255, 1);
                border: none;
            }

            .custom-select {
                padding: 0 0 0 5px;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23ffffff%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23ffffff%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
                background-size: .6em;
                background-position: calc(100% - 0.8em) center;
                background-repeat: no-repeat;
            }

            .left-label {
                justify-content: center;
            }
        }

        .aoi-2fields-wrapper {
            justify-content: space-between;
        }

        .surge-and-dive-wrapper {
            margin-top: 25px !important;
        }

        .mt-22 {
            margin-top: 22px;
        }
    }
</style>
